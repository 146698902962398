<template>
  <div id="article-editor">
    <adminArticle :sign="1"></adminArticle>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import adminArticle from "@/components/admin-article/admin-article.vue"

export default defineComponent({
  components: {
    adminArticle
  },
  setup() {
    return {}
  }
})
</script>

<style lang="scss">

</style>